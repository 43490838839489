import { Container } from '@fify.at/gatsby-theme-base/src/components/Grid';
import Layout from '@fify.at/gatsby-theme-base/src/components/Layout/Layout';
import React from 'react';

export default function FourOhFourPage(props) {
  return (
    <Layout {...props} title="Nichts gefunden">
      <Container>
        <p>Nichts gefunden!</p>
      </Container>
    </Layout>
  );
}
